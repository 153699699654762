<template>
    <div class="sidebar-page">
        <section class="sidebar-layout">
            <o-sidebar position="static"
                       :mobile="mobile"
                       :expand-on-hover="expandOnHover"
                       :fullheight="true"
                       :reduce="reduced"
                       open>
                <div class="p-1" style="min-height:100vh">
                    <div class="block column is-centered">
                        <a href="/">
                            <img class="image" v-show="!reduced" style="width: 300px" src="@/assets/logos/aag-full.png" />
                            <img class="image is-centered" v-show="reduced" style="width: 90px" src="@/assets/logos/aag-small.png" />
                        </a>
                    </div>
                    <p @click="reduced = !reduced" style="color: white;" class="has-text-centered" v-show="reduced"><o-icon icon="chevron-right" class="pointer hover icon-circle"></o-icon></p>
                    <p @click="reduced = !reduced" style="color: white;" class="is-pulled-right" v-show="!reduced"><o-icon icon="chevron-left" class="pointer hover icon-circle"></o-icon></p>
                    <div class="p-1 admin-links">
                        <div class="level">
                            <div class="level-left">
                                <router-link :to="{ name: 'searchUsers' }"><o-icon pack="far" icon="user" size="medium"></o-icon><span v-show="!reduced">Search Users</span></router-link>
                            </div>
                        </div>
                        <div class="level">
                            <div class="level-left">
                                <router-link :to="{ name: 'quoteReport' }"><o-icon pack="far" icon="file-alt" size="medium"></o-icon><span v-show="!reduced">RFQ Report</span></router-link>
                            </div>
                        </div>
                        <div class="level">
                            <div class="level-left">
                                <router-link :to="{ name: 'adminGrilleTypes' }"><o-icon pack="far" icon="border-all" size="medium"></o-icon><span v-show="!reduced">Grille Management</span></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </o-sidebar>
            <div class="admin-container">
                <!--<div class="navbar-brand">-->
                <a class="navbar-burger" role="button" aria-label="menu" aria-expanded="false" data-target="navbarAdmin" v-show="route.startsWith('/admin/grille')">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
                <!--</div>-->
                <div class="navbar-menu" id="navbarAdmin">
                    <div class="navbar-start" v-show="route.startsWith('/admin/grille')">
                        <router-link class="navbar-item" :to="{ name: 'adminGrilleTypes' }">Grille Types</router-link>
                        <router-link class="navbar-item" :to="{ name: 'adminGrilleStyleTypes' }">Style Types</router-link>
                        <router-link class="navbar-item" :to="{ name: 'adminGrillePatternTypes' }">Patterns</router-link>
                        <router-link class="navbar-item" :to="{ name: 'adminGrilleFrameTypes' }">Frame Types</router-link>
                        <router-link class="navbar-item" :to="{ name: 'adminGrilleMaterialTypes' }">Material Types</router-link>
                        <router-link class="navbar-item" :to="{ name: 'adminGrilleFinishTypes' }">Finish Types</router-link>
                        <router-link class="navbar-item" :to="{ name: 'adminApplicationTypes' }">Application Types</router-link>
                    </div>
                </div>
                <div class="p-1">
                    <router-view></router-view>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    export default {
        props: {},
        emits: [],
        setup(props, context) {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            const expandOnHover = ref(false);
            const mobile = ref("reduced");
            const reduced = ref(false);

            const route = computed(() => {
                return global?.$route.fullPath;
            });

            const SetupHamburger = () => {
                const navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
                if (navbarBurgers.length > 0) {
                    navbarBurgers.forEach(el => {
                        el.addEventListener('click', () => {
                            const target = document.getElementById(el.dataset.target);
                            el.classList.toggle('is-active');
                            target.classList.toggle('is-active');
                        });
                    })
                }
            }

            onMounted(() => {
                SetupHamburger();
            });

            return {
                expandOnHover,
                mobile,
                reduced,
                route
            }
        }
    };
</script>

<style lang="scss">
    @import "@/assets/css/globalVars.scss";

    body, html {
        padding: 0;
        height: 100vh;
        overflow-y: visible;
    }

    .admin-container {
        height: 100vh;
        overflow-y: auto;
    }

    .navbar-burger {
        z-index: 2;
        position: relative;
        margin-bottom: -3.25rem;
        right: 0;
        background-color: #233d5d;
        color: white;

        &:hover {
            background-color: lighten(#233d5d, 10%);
            color: white;
        }
    }

    .sidebar-page .logo {
        max-height: 100px;
    }

    .sidebar-page .icon {
        vertical-align: middle;
    }

    .sidebar-page {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100%;
        margin-top: -52px;
        /*position: fixed;
        top: 0;
        bottom: 0;*/
    }

        .sidebar-page .sidebar-layout {
            display: flex;
            flex-direction: row;
            min-height: 100%;
        }

        .sidebar-page .sidebar-content {
            background-color: #233d5d !important;
        }

        .sidebar-page .admin-links {
            color: white;
        }

            .sidebar-page .admin-links a {
                color: white;
            }

                .sidebar-page .admin-links a:hover {
                    color: darkgrey;
                }

        .sidebar-page .navbar-menu {
            background-color: #233d5d;
        }

            .sidebar-page .navbar-menu .navbar-item {
                color: white;
            }

                .sidebar-page .navbar-menu .navbar-item:hover {
                    color: black;
                }

        .sidebar-page .admin-container {
            width: 100%;
        }

        .sidebar-page .icon-circle {
            border: solid white 1px;
            border-radius: 100%;
            /*box-shadow: 0px 1px 2px rgba(185, 185, 185, 0.75);*/
        }
</style>